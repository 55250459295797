/** @module agent_api 薬キャリエージェントの各種APIを実行するモジュールファイル */

import axios from 'axios'

/**
 * 薬キャリエージェントの各種APIを実行するためのAxiosインスタンス
 */
const axiosInstance = axios.create({
  headers: {
    // CSRF対策トークンをリクエストヘッダーに設定する
    'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]')?.getAttribute('content'),
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export default axiosInstance;
